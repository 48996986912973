/* .ups-boxes-best-borders:nth-child(1) {
    border: 3px solid rgb(255 215 0);
    background-color: rgb(255 215 0 / 50%);
}

.ups-boxes-best-borders:nth-child(2) {
    border: 3px solid rgb(192 192 192);
    background-color: rgb(192 192 192 / 50%);
}

.ups-boxes-best-borders:nth-child(3) {
    border: 3px solid rgb(205, 127, 50);
    background-color: rgb(205 127 50 / 50%);
} */

.selected {
    /* look to replace !important */
    border: 3px solid blue !important
}