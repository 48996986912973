.main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    display: flex;
    justify-content: center;
}

.box-type {
    display: flex;
    justify-content: center;
}