.user-input-container {
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.user-input {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.user-input-weight {
    display: flex;
    justify-content: center;
}

.user-input-top {
    width: 111px;
}

.user-input-postalCode {
    width: 60px;
}